<template>
    <div id="main">
        <div class="login-box">
            <div class="title">
                <el-image
                    style="width: 80px; height: 80px"
                    :src="logo"
                    :fit="'contain'">
                </el-image>
                <h1>科房超级管理系统</h1>
            </div>
            <!-- <div class="form-control">
                <input type="value" v-model="logindata.account" required="" autocomplete="off">
                <label>
                    <span style="transition-delay:0ms">U</span><span style="transition-delay:50ms">s</span><span style="transition-delay:100ms">e</span><span style="transition-delay:150ms">r</span><span style="transition-delay:200ms">n</span><span style="transition-delay:250ms">a</span><span style="transition-delay:300ms">m</span><span style="transition-delay:350ms">e</span>
                </label>
            </div>
            <div class="form-control">
                <input type="password" v-model="logindata.password" required="" autocomplete="off">
                <label>
                    <span style="transition-delay:0ms">P</span><span style="transition-delay:50ms">a</span><span style="transition-delay:100ms">s</span><span style="transition-delay:150ms">s</span><span style="transition-delay:200ms">w</span><span style="transition-delay:250ms">o</span><span style="transition-delay:300ms">r</span><span style="transition-delay:350ms">d</span>
                </label>
            </div> -->
            <div class="title">
                <el-image
                    style="width: 250px; height: 250px;overflow: hidden;"
                    :src="qrcode"
                    :fit="'contain'">
                </el-image>
            </div>
            <!-- <div class="loginetr">
                <button class="login_btn" @click="trylogin">登录</button>
            </div> -->
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            logo:this.$logo,
            t:new Date().getTime(),
            qrcode:this.$zclocal+"webqrcode/",
            logindata:{
                account:"",
                password:""
            },
            timer:null
        }
    },
    mounted() {
        //设置一个定时器，每2秒请求一次qrlogin，直到返回200

        this.getunlimited()
        this.timer = setInterval(() => {
            this.qrlogin()
        }, 2000);

    },
    methods: {
        getunlimited(){
            axios({
                url: this.$zclocal+this.$api.zcnormal+"?mode=getunlimited&time="+this.t,
            }).then((res)=>{
                this.qrcode = this.qrcode+res.data
            })
        },
        qrlogin(){
            axios({
                url: this.$zclocal+this.$api.zcnormal+"?mode=qrlogin&t="+this.t,
            }).then((res)=>{
               if(res.data.code == 200){
                     clearInterval(this.timer)
                    //  将用户数据缓存到本地
                    localStorage.setItem('token',res.data.token)
                    this.$message.success("恭喜你，登陆成功")
                    this.$router.push({path:'/'})
               }   
            })
        },
        trylogin(){
            if(this.logindata.account.length == 0 || this.logindata.password.length == 0){
                this.$message({
                    message:"请检查用户名或密码是否输入",
                    type:"warning"
                })
            }else{
                axios({
                    method:'post',
                    url: this.$host+this.$api.login,
                    data: {
                        account:this.logindata.account,
                        password:this.logindata.password,
                    }
                }).then((res)=>{
                    if(res.data == "error"){
                        this.$message.error("账号密码错误，请检查大小写，或数字键盘未打开")
                    }else{
                        localStorage.setItem('token',res.data)
                        this.$message.success("恭喜你，登陆成功")
                        this.$router.push({path:'/'})
                    }
                    
                })
            }
        }
    },
}
</script>
<style scoped>
    .title{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .title h1{
        text-align: center;
        color: #fff;
        font-size: 29px;
    }
    #main{
        width: 100%;
        height: 100vh;
        background:url(../assets/img/login.jpg) no-repeat center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content:center;
    }
    .form-control {
    position: relative;
    margin: 40px 0 70px;
    width: 368px;
    }

    .form-control input {
    background-color: transparent;
    border: 0;
    border-bottom: 2px #fff solid;
    display: block;
    width: 100%;
    padding: 15px 0;
    font-size: 18px;
    color: #fff;
    }

    .form-control input:focus,
    .form-control input:valid {
    outline: 0;
    border-bottom-color: lightblue;
    }

    .form-control label {
    position: absolute;
    top: 15px;
    left: 0;
    pointer-events: none;
    }

    .form-control label span {
    display: inline-block;
    font-size: 18px;
    min-width: 5px;
    color: #fff;
    transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .form-control input:focus+label span,
    .form-control input:valid+label span {
    color: lightblue;
    transform: translateY(-30px);
    }
    .loginetr{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .login_btn {
    --glow-color: rgb(255, 255, 255);
    --glow-spread-color: rgba(255, 255, 255, 0.5);
    --enhanced-glow-color: rgb(255, 255, 255);
    --btn-color: rgb(255, 255, 255);
    border: .0025em solid var(--glow-color);
    padding: 1em 3em;
    color: #000;
    font-size: 15px;
    font-weight: bold;
    background-color: var(--btn-color);
    border-radius: 1em;
    outline: none;
    box-shadow: 0 0 1em .05px var(--glow-spread-color),
            0 0 2em 1em var(--glow-spread-color),
            inset 0 0 .05em .05em var(--glow-spread-color);
    text-shadow: 0 0 .25em var(--glow-color);
    position: relative;
    transition: all 0.3s;
    }

    .login_btn::after {
    pointer-events: none;
    content: "";
    position: absolute;
    top: 120%;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--glow-spread-color);
    filter: blur(2em);
    opacity: .7;
    transform: perspective(1.5em) rotateX(35deg) scale(1, .6);
    }

   .login_btn:hover {
    color: #ccc;
    background-color: var(--glow-color);
    box-shadow: 0 0 0.5em .25em var(--glow-color),
            0 0 1em 1em var(--glow-spread-color),
            inset 0 0 .05em .05em var(--glow-color);
    }

    .login_btn:active {
    box-shadow: 0 0 0.6em .25em var(--glow-color),
            0 0 2.5em 2em var(--glow-spread-color),
            inset 0 0 .5em .25em var(--glow-color);
    }
</style>